<template>
    <div>
        <v-dialog  v-model="dialog"  width="500px" persistent>
            <v-card class="pt-9">

                <v-card-text class="subtitle font-weight-bold  text-center">
                    ¡Hola! Estás a un paso de iniciar tu viaje para ser parte de la familia Konecta
                </v-card-text>

                <v-card-text class="body-2 black--text font-weight-bold text-center">
                    Te pediremos tus datos personales y profesionales para iniciar
                    con tu proceso de seleción*
                </v-card-text>

                <v-container class=" d-flex  justify-center">
                    <v-checkbox
                    @click="checkEvents"
                        v-model="dataPermission"
                        label="Acepto compartir mis datos e iniciar mi proceso de selección"
                        color="teal"
                        :value="true"
                        hide-details
                    ></v-checkbox>
                </v-container>

                <v-card-actions class="justify-center pb-4">
                    <v-btn v-if="dataPermission" color="#FA7048" class="white--text"
                    @click="initProcess">
                        Iniciar proceso
                    </v-btn>
                    <v-btn  v-else disabled color="#FA7048" class="white--text"
                    @click="initProcess">
                        Iniciar proceso
                    </v-btn>
                </v-card-actions>

                <v-card-text class="caption text-center">
                   *Tus datos no serán compartidos con ninguna empresa y
                    los usaremos únicamente para analizar el perfil de tu trabajo.
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
  name: 'init_modal_agile_flow',
  data() {
    return {
      dataPermission: false,
    };
  },
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    checkEvents() {
      this.$emit('check');
    },
    initProcess() {
      this.dialog = false;
      this.$emit('acceptPermission');
    },
  },
};
</script>

<style>
.v-dialog {
    border-radius: 18px !important;
}

.subtitle {
    color: teal !important;
    font-size: 1.05rem !important;
    line-height: 1.5rem !important;
}

.v-card__subtitle {
    color: rgba(210, 29, 29, 0.6) !important;
}

.v-label {
    color: black  !important;
    font-size: 13px;
    text-justify: center;
}

.v-input__slot {
    padding: 0px 15px 0px 15px;
}

.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>
